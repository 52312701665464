import * as React from "react"
import Header from "./header"
import Footer from "./footer"
import Head from "./head"
import "../styles.css.ts";
import UserProvider from "./contextProvider/userProvider";

export default function Layout(props) {
  return (
    <UserProvider>
      <>
        <Head {...props} />
        <Header  {...props} />

        {props.children}
        <Footer />
      </>
    </UserProvider>
  )
}
