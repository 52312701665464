import { StaticImage } from 'gatsby-plugin-image';
import React, { useContext, useState } from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import {
    Container, Section, Flex, Box, Text, SubheadSmall, Button, Link,
    ContactCard, ContactCardHeader, ContactCardBody, FlexList, MediumBoldText, Subhead, Card, lock_icon
} from '../components/ui';
import { background_forgot, forgot_card, forgot_inputTextBox, submit_btn } from '../components/ui.css';
import login_background_img from '../images/login_background_img.png';
const Forgot = () => {
    const registrationform = {
        email: "",
       
    }
    const [errormessage, seterrorMessage] = useState(registrationform)
    const [createuser, setCreateuser] = useState(registrationform)
    const handledata = (e) => {
        const { name, value } = e.target
        setCreateuser({
            ...createuser,
            [name]: value
        })
    }
    const validateEmail = (email) => {
        const regEx = RegExp(/\S+@\S+\.\S+/);
        return regEx.test(String(email).toLocaleLowerCase());
    }
    const validateRegisterForm = () => {
        let formData = createuser;
        let errormessage = { ...registrationform, isValidationFailed: false }
        if (formData.email === "") {
            errormessage = {
                ...errormessage,
                email: "Please enter the Email",
                isValidationFailed: true
            }
        }
        if (formData.email !== "") {
            let isEmailvalid = validateEmail(formData.email);
            if (!isEmailvalid) {
                errormessage = {
                    ...errormessage,
                    email: "Please enter valid Email",
                    isValidationFailed: true
                }
            }
        }
        return errormessage
    }
    const renderErrorMessage = (field) => {
        return (
            errormessage.isValidationFailed && errormessage[field] !== "" &&
            <Text> {errormessage[field]}</Text>
        )
    }
    const handlesubmit = () => {
        const formData = {
            email: createuser.email
        }
        let validationform = validateRegisterForm();
        if (validationform.isValidationFailed) {
            seterrorMessage(validationform)
        }
    }
    return (
        <Layout navName="Home">
            {/* <Seo  title="Home" > */}
        <Section className={background_forgot}>
                <Container >
                    <Flex gap={1} variant="responsive">
                        
                        <Box>
                            <Card className={forgot_card}>
                                <Container>
                                <Flex gap={1} variant="responsive">
                                    <Box></Box>
                                    <Box><StaticImage src="../images/lock_icon.png" width={80} /></Box>
                                    <Box></Box>
                                </Flex>
                                <Flex gap={1} variant="responsive">
                                    <Box><Subhead>Forgot your Password?</Subhead></Box>
                                </Flex>
                                <form onSubmit={handlesubmit}>
                                    <Text></Text>
                                    <Text>
                                        <label>Email</label>
                                        <input type="text" name="username" className={`${forgot_inputTextBox} $ "feild"`}
                                            onChange={handledata}
                                            value={createuser.email}
                                            invalid={errormessage.email == "" ? false : true}
                                        />
                                        {renderErrorMessage("email")}
                                    </Text>
                                    <Text>
                                        <input type="submit" className={submit_btn} value="submit"/></Text>
                                    <Text></Text>
                                    <Link to="/Login"><label style={{ color: "#11294D", cursor: "pointer" }} > {' '} Remembered your password? Log in</label></Link>
                                    <Text></Text>
                                </form>
                                </Container>
                            </Card>
                        </Box>
                       
                    </Flex>
                </Container>
        </Section>
        {/* </Seo> */}
        </Layout>
    )
}
export default Forgot 